@import "variables";
@import "framework/framework";

html {
	font-family: "alternate-gothic-no-3-d";
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	overflow-x: hidden;
	text-transform: uppercase;
}

body {
	background: $color-tan;
	color: $color-blue;
	overflow-x: hidden;
}

a {
	color: $color-blue;

	@include hover-focus {
		color: $color-blue-2;
	}
}

.container {
	max-width: 970px;
}

.btn {
	background: $color-blue;
	border: 0;
	color: $color-white;
	display: inline-block;
	font-size: 18px;
	padding: 8px 8px 4px 8px;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;

	@include hover-focus {
		background: $color-blue-2;
		color: $color-white;
	}
}

header {
	position: relative;

	> img {
		left: 50%;
		position: relative;
		transform: translateX(-50%);
		width: initial;

		@include tablet-down {
			transform: none;
		}

		&:nth-child(1) {
			@include tablet-down {
				display: none;
			}
		}

		&:nth-child(2) {
			display: none;
			@include tablet-down {
				display: block;
				left: 0;
				width: 100%;
			}
		}
	}

	.container {
		left: 0;
		position: absolute;
		right: 0;
		top: 115px;

		@include tablet-down {
			margin-top: -25px;
			position: relative;
			text-align: center;
			top: 0;
			z-index: 2;
		}

		> .content {

			@include tablet-down {
				padding-bottom: 50px;
			}

			> h1 {
				margin: 0;

				> img {
					@include tablet-down {
						margin: 0 auto;
					}
				}
			}

			> h2 {
				color: $color-blue-2;
				font-size: 22px;
				font-weight: 400;
				margin: 20px 0;

				> span {
					color: $color-gray-2;
					display: block;
					font-family: "alternate-gothic-no-2-d";
					font-size: 45px;
				}
			}

			> form {
				> div {
					margin-bottom: 15px;

					> label {
						display: block;
						font-size: 16px;
						margin-bottom: 2px;
					}

					> input {
						background: $color-white;
						border: 1px solid $color-gray;
						color: $color-black;
						display: block;
						padding: 5px;
						width: 170px;

						@include tablet-down {
							margin: 0 auto;
						}

						&:focus {
							border-color: $color-black;
							outline: 0;
						}
					}
				}

				.btn {
					min-width: 85px;
				}
			}

			> .thanks {
				color: $color-blue-2;
			}
		}
	}
}

main {
	min-height: 500px;

	#videos {
		display: none;
		text-align: center;

		#ugc {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 0 -5px;

			> article {
				flex: 0 0 33.333333%;
				margin-bottom: 10px;
				padding: 0 5px;

				@include tablet-down {
					flex: 0 0 50%;
				}

				@include phone-sm-down {
					flex: 0 0 100%;
				}

				> a {
					display: block;
					opacity: 1;

					@include hover-focus {
						opacity: 0.8;
					}

					> img {
						width: 100%;
					}
				}
			}
		}

		.btn {
			margin-top: 15px;
			min-width: calc(33.333333% - 5px);
		}
	}
}

footer {

	padding: 52px 0 14px 0;
	text-align: center;

	.icons {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: 16px;
			line-height: 1;
			vertical-align: middle;

			+ li {
				margin-left: 7px;
			}

			> a {
				color: $color-gray;
				display: inline-block;
				opacity: 1;

				@include hover-focus {
					opacity: 0.8;
				}

				> svg {
					height: 12px;
					width: auto;

					path {
						fill: $color-gray;
						fill-rule: evenodd;
					}
				}
			}

			&:last-child {
				position: relative;
			}
		}

	}

	.copyright {
		color: $color-black;
		font-size: 8px;
		font-weight: bold;
		letter-spacing: letter-spacing(200);
		line-height: line-height(14px, 8px);
		margin-top: 10px;
		text-indent: text-indent(200);

		a {
			color: $color-black;

			@include hover-focus {
				color: $color-black;
				text-decoration: underline;
			}
		}
	}
}